import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../services/authServices';
import { useApp } from "../context/appContext";

import {useNavigate} from "react-router-dom";

const AccountDropdown = () => {
    const { userName} = useApp();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate(); // Initialize the navigate function
    const { logout } = useAuth();


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        // Implement logout logic here
        try {
            await logout();
            // Redirect to the protected route after successful login

            console.log('Login successful, nav to chat');
            navigate('/');
        } catch (error) {
            console.error('Login failed:', error);
        }

        console.log('Logging out...');
    };

    const handleSettings = () => {
        // Navigate to settings or open settings modal
        console.log('Navigating to settings...');
    };

    return (
        <div className="account-dropdown" ref={dropdownRef}>
            <button className={'account-dropdown-title'} onClick={toggleDropdown}>
                {userName} {isOpen ? '▼' : '▼'}
            </button>

            {isOpen && (
                <ul className="account-dropdown-menu">
                    <li onClick={handleSettings}>Settings</li>
                    <li onClick={handleLogout}>Logout</li>
                </ul>
            )}
        </div>
    );
};

export default AccountDropdown;
