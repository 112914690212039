import axios from 'axios';
import { AuthContext } from "../context/authContext";
import { AppContext } from "../context/appContext";

import {useContext} from "react";
import { SERVER_URL } from '../constants/constants'; // Import constants
axios.defaults.withCredentials = true;

export const useAuth = () => {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const {setUsername, setSelectedChat } = useContext(AppContext)

    const login = async (username, password) => {
        try {
            const response = await axios.post(`${SERVER_URL}/login`, { username, password });
            if (response.status === 201) {
                console.log(response.data.message);  // "User authenticated"
                setIsLoggedIn(true);
                setUsername(username);
                setSelectedChat(-1)
            } else {
                console.error('Invalid credentials');
                // Handle authentication failure here (e.g., show an error message)
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.error('Error during login:', error);
            // Handle other potential errors (e.g., network issues)
            setIsLoggedIn(false);
            throw error;
        }
    };

    const logout = async () => {
        try {
            const response = await axios.post(`${SERVER_URL}/logout`, {}, { withCredentials: true });
            if (response.status === 200) {
                console.log("Logged out successfully");
                // Further client-side logic here, like redirecting to login page
                setIsLoggedIn(false);
                setSelectedChat(-1)
            }
        } catch (error) {
            console.error("An error occurred during logout:", error);
        }
    };

    const checkSession = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/check-session`, {
                withCredentials: true, // Important for sending cookies
            });

            setIsLoggedIn(true);
            setUsername(response.data.username);
            return response.status === 200;
        } catch (error) {
            setIsLoggedIn(false);
            return false; // Session is not active, or an error occurred
        }
    };

    const register = async (username, password) => {
        try {
            await axios.post(`${SERVER_URL}/register`, { username, password });
            setIsLoggedIn(true);
            setUsername(username);
            setSelectedChat(-1)
        } catch (error) {
            throw error;
        }
    };

    return {
        isLoggedIn,
        setIsLoggedIn,
        login,
        logout,
        checkSession,
        register
    };
};
