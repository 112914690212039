import React, {useState, useEffect, useContext} from 'react';
import axios from "axios";
import {SERVER_URL} from '../constants/constants';
import {AppContext} from "../context/appContext";

const TrainingPanel = () => {
    const [learningRate, setLearningRate] = useState(0.01);
    const [samples, setSamples] = useState(5);
    const [epochs, setEpochs] = useState(10);
    const [trainingStatus, setTrainingStatus] = useState('idle');
    const [error, setError] = useState(null);
    const [queuePosition, setQueuePosition] = useState(null);
    const [result, setResult] = useState(null);
    const {
        chatList, visibleChatList, updateMessageData, selectedChat, setSelectedChat, updateLatestMessage,
        selectedModel, hasScrolled, setHasScrolled, userScroll, setUserScroll, applyChatList
    } = useContext(AppContext);

    useEffect(() => {
        let eventSource;

        const setupEventSource = () => {
            eventSource = new EventSource(`${SERVER_URL}/trainmodel`);

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.status === 'queued') {
                    setTrainingStatus('queued');
                    setQueuePosition(data.position);
                } else if (data.status === 'completed') {
                    setTrainingStatus('completed');
                    setResult(data.result);
                    eventSource.close();
                }
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                // setError('Failed to connect to server');
                setTrainingStatus('completed');
                eventSource.close();
            };
        };

        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
    }, []);

    const handleStartTraining = async () => {
        console.log('Start training with:', {learningRate, samples, epochs});

        // setTrainingStatus('starting');
        setError(null);
        setResult(null);
        setQueuePosition(null);
        let messagesInChat = chatList[selectedChat].messages

        try {
            await axios.post(`${SERVER_URL}/trainmodel`, {
                chat: messagesInChat,
                data: "Hello my name is Lukas",
                learningRate,
                samples,
                epochs
            });

            const setupEventSource = () => {
                const eventSource = new EventSource(`${SERVER_URL}/trainmodel`);

                eventSource.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data.status === 'queued') {
                        // setTrainingStatus('queued');
                        setQueuePosition(data.position);
                    } else if (data.status === 'completed') {
                        // setTrainingStatus('completed');
                        setResult(data.result);
                        eventSource.close();
                    }
                };

                eventSource.onerror = (error) => {
                    console.error('EventSource failed:', error);
                    // setError('Failed to connect to server');
                    setTrainingStatus('completed');
                    eventSource.close();
                };
            };

            setupEventSource();
        } catch (err) {
            // console.error('Error starting training:', err);
            // setError('Failed to start training');
            setTrainingStatus('error');
        }
    };

    const renderStatus = () => {
        switch (trainingStatus) {
            case 'starting':
                return <p>Starting training...</p>;
            case 'queued':
                return <p>In queue. Position: {queuePosition}</p>;
            case 'completed':
                return <p>Training completed.</p>;
            case 'error':
                return <p style={{color: 'red'}}>Error: {error}</p>;
            default:
                return null;
        }
    };

    return (
        <div style={{
            position: 'fixed',
            right: 0,
            top: '45px',
            bottom: 0,
            zIndex: 0,
            width: '250px',
            paddingTop: '65px',
            padding: '20px',
            backgroundColor: '#111112',
            color: 'white',
            boxSizing: 'border-box',
            overflowY: 'auto'
        }}>
            <h3 style={{
                marginTop: 50,
                marginBottom: '20px',
                color: '#6ab7f6',
                fontWeight: 'bold',
            }}>Hyper Parameters</h3>

            <div style={{marginBottom: '15px'}}>
                <label style={{display: 'block', marginBottom: '5px'}}>Learning Rate</label>
                <input
                    type="number"
                    value={learningRate}
                    onChange={(e) => setLearningRate(parseFloat(e.target.value))}
                    style={{
                        width: '100%',
                        padding: '5px',
                        backgroundColor: '#2d2d2d',
                        border: '1px solid #3d3d3d',
                        color: 'white',
                        borderRadius: '4px'
                    }}
                />
            </div>

            <div style={{marginBottom: '15px'}}>
                <label style={{display: 'block', marginBottom: '5px'}}>Samples</label>
                <input
                    type="number"
                    value={samples}
                    onChange={(e) => setSamples(parseInt(e.target.value))}
                    style={{
                        width: '100%',
                        padding: '5px',
                        backgroundColor: '#2d2d2d',
                        border: '1px solid #3d3d3d',
                        color: 'white',
                        borderRadius: '4px'
                    }}
                />
            </div>

            <div style={{marginBottom: '20px'}}>
                <label style={{display: 'block', marginBottom: '5px'}}>Epochs</label>
                <input
                    type="number"
                    value={epochs}
                    onChange={(e) => setEpochs(parseInt(e.target.value))}
                    style={{
                        width: '100%',
                        padding: '5px',
                        backgroundColor: '#2d2d2d',
                        border: '1px solid #3d3d3d',
                        color: 'white',
                        borderRadius: '4px'
                    }}
                />
            </div>

            <button
                onClick={handleStartTraining}
                disabled={trainingStatus === 'starting' || trainingStatus === 'queued'}
                style={{
                    width: '100%',
                    padding: '10px',
                    backgroundColor: trainingStatus === 'starting' || trainingStatus === 'queued' ? '#cccccc' : '#FFD700',
                    color: 'black',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: trainingStatus === 'starting' || trainingStatus === 'queued' ? 'not-allowed' : 'pointer',
                    fontSize: '16px',
                    fontWeight: 'bold'
                }}
            >
                {trainingStatus === 'starting' || trainingStatus === 'queued' ? 'Training...' : 'Start Training'}
            </button>

            <div style={{marginTop: '20px'}}>
                {renderStatus()}
            </div>
        </div>
    );
};

export default TrainingPanel;