import React from 'react';

const LoadingOverlay = ({message = "Loading base model weights..."}) => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
            }}>
                <div style={{
                    width: '48px',
                    height: '48px',
                    border: '4px solid #3b82f6',
                    borderTopColor: 'transparent',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite',
                    marginBottom: '16px'
                }}/>
                <p style={{
                    color: '#374151',
                    height: '8px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>{message}</p>
            </div>
        </div>
    );
};

const spinKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Add the keyframes to the document
if (typeof document !== 'undefined') {
    const style = document.createElement('style');
    style.innerHTML = spinKeyframes;
    document.head.appendChild(style);
}

export default LoadingOverlay;