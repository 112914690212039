import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useAuth} from "../services/authServices";

const SignUpWizard = ({setIsVisible, setShowLogin}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(true);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    const emailInputRef = useRef(null);
    const navigate = useNavigate();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    const {register } = useAuth();

    useEffect(() => {
        emailInputRef.current.focus();
    }, []);

    const validateEmail = (e) => {
        setEmail(e.target.value);
        const email = e.target.value;
        if (email.includes('@') && email.includes('.')) {
            setEmailError(null);
        } else {
            setEmailError('Invalid email address');
        }
    };

    useEffect(() => {
        // Enable the Create Account button only if email and password are valid
        // Enable the Create Account button only if email and password are valid
        if (email.includes('@') && email.includes('.') && password.length >= 8) {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    }, [email, password]);

    const handleSignInRedirect = async () => {
        setIsVisible(false);
        setShowLogin(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && isButtonEnabled) {
            createAccount();
        }
    };

    const createAccount = async () => {
        // Close the sign-up window and redirect to sign-in page
        try {
            await register(email, password);
            setEmailError('Success')
            navigate('chat')
        } catch (error) {
            if (error.response && error.response.status === 409) {
                // Conflict, user already exists
                setEmailError('Username already exists')
                console.log('Error:', error.response.data); // error.response.data should contain your JSON payload
            } else if (error.response && error.response.status === 400) {
                // Bad Request, likely "password too short"
                setPasswordError('Password too short')
                console.log('Error:', error.response.data);
            } else {
                // Some other issue
                setEmailError('An unknown error occurred')
                console.log('An unknown error occurred:', error);
            }
        }
    }
    return (
        <div className={'signup-container'}>
            <div className={'signup'}>
                <div className={'signup-inner'}>
                    <button className={'close-button'} onClick={() => setIsVisible(false)}>X</button>
                    <div className={'signup-welcome'}>Create Account</div>
                    <div className={'signup-field'}>
                        <label htmlFor="email" onClick={() => emailInputRef.current.focus()}>Email:</label>
                        <input
                            ref={emailInputRef}
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            required
                            autoCorrect="off"
                            onKeyDown={handleKeyDown}
                            onChange={validateEmail}
                        />
                        {emailError && <div className="error-message">{emailError}</div>}
                    </div>
                    <div className={'signup-field'}>
                        <label htmlFor="password" onClick={() => document.getElementById('password').focus()}>Password:</label>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                value={password}
                                required
                                minLength={8}
                                autoCorrect="off"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {passwordError && <div className="error-message">{passwordError}</div>}
                            <button
                                style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? 'Hide' : 'Show'}
                            </button>
                        </div>
                        <div className="password-requirements">Requires at least 8 characters</div>
                    </div>
                    <div className={'signup-action'}>
                        <button type="submit" disabled={!isButtonEnabled} className={isButtonEnabled ? 'enabled' : 'disabled'} onClick={createAccount}>Create Account</button>
                    </div>
                    <div className={'signup-or'}>
                    <div className={'signup-signin'}>
                        Already have an account? <Link className={'blue-link'} to="/" onClick={handleSignInRedirect}>Sign in</Link>
                    </div>
                    <div className={'signup-disclaimer'}>
                        By signing up you agree to our <Link className={'blue-link'} to="/terms">Terms of Service</Link> and <Link className={'blue-link'} to="/privacy">Privacy Policy</Link>.
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpWizard;
