import React from 'react';
import { useAuth } from '../services/authServices';
import { ReactComponent as Logo} from '../assets/logo.svg';

import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AccountDropdown from "./accountDropdown";

const NavBarLanding = ({loginClicked, handleRegister}) => {
    const { formatMessage: f } = useIntl();  // Destructure and rename
    const {isLoggedIn } = useAuth();

    const handleLogin = () => {
        loginClicked();
    };

    return (

        <nav>
            <div className={"nav-container"}>

                <div className={"nav-brandname"}>
                    <Logo className={"logo"} style={{width:'30px', height:'30px'}}/>
                    <a href={'/'}><b>{f({ id: 'hexis_brand' })}</b></a>
                </div>

                <ul className={"nav-list"}>
                    {!isLoggedIn &&  <button onClick={handleRegister} className="get-started-button">
                        Get a demo
                    </button>}

                    {!isLoggedIn && <li><Link onClick={handleLogin}>Sign In</Link></li>}
                    {isLoggedIn &&   <li><Link to="/chat">{f({ id: 'chat_nav' })}</Link></li>}
                    {isLoggedIn &&  <AccountDropdown/>}
                </ul>
            </div>
        </nav>
    );
};

export default NavBarLanding;
