import React, { useEffect, useRef, useState, memo, useMemo } from 'react';
import '../styles/ChatContainer.css';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import * as languages from 'react-syntax-highlighter/dist/esm/languages/hljs';
import { FaCopy } from 'react-icons/fa';
import copy from 'copy-to-clipboard';
import ReactMarkdown from 'react-markdown';

Object.keys(languages).forEach((lang) => {
    SyntaxHighlighter.registerLanguage(lang, languages[lang]);
});

function ChatBubble({ index, message, isSender, setHeight }) {
    const bubbleRef = useRef(null);
    const bubbleClass = isSender ? "chat-bubble-sender" : "chat-bubble-receiver";
    const bubbleContainerClass = isSender ? "bubble-container-sender" : "bubble-container-receiver";
    const [copied, setCopied] = useState([]);

    useEffect(() => {
        if (bubbleRef.current && message) {
            const height = bubbleRef.current.getBoundingClientRect().height + 10;
            setHeight(index, height);
        }
    }, [message, index, setHeight]);


    const copyToClipboard = (code, index) => {
        copy(code);
        setCopied((prev) => {
            const newCopied = [...prev];
            newCopied[index] = true;
            return newCopied;
        });
        setTimeout(() => {
            setCopied((prev) => prev.map((_, idx) => idx === index ? false : _));
        }, 2000);
    };

    const RenderCodeBlock = memo(({ language, code, key }) => (
        <div>
            <div className="code-block-language">
                <span className={'code-block-language-name'}>{language}</span>
                <div className="copy-button" onClick={() => copyToClipboard(code, key)}>
                    <FaCopy />
                    <span>{copied[key] ? 'Copied...' : 'Copy Code'}</span>
                </div>
            </div>
            <div className="code-block" key={key}>
                <SyntaxHighlighter language={language} wrapLines={true} lineProps={
                    {style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}} style={dracula}>
                    {code}
                </SyntaxHighlighter>
            </div>
        </div>
    ));

    const sections = useMemo(() => {
        if (!message) return null;

        let remainingText = message;
        let match;
        const newSections = [];
        const components = {
            code({node, inline, className, children, ...props}) {
                return !inline ? (
                    <span className="markdown-code-block">
          <code className={className} {...props}>
            {children}
          </code>
        </span>
                ) : (
                    <code className={className} {...props}>
                        {children}
                    </code>
                );
            }
        };

        while ((match = /```(\w+)?\n([\s\S]*?)(```|$)/.exec(remainingText)) !== null) {
            const [fullMatch, language = 'text', code] = match;

            if (match.index > 0) {
                newSections.push(
                    <div key={newSections.length}>
                        {!isSender ? (
                        <ReactMarkdown components={components} className="markdown-content" children={remainingText.slice(0, match.index)} />
                        ) : (
                        <div className={'preserve-whitespace'}>{remainingText.slice(0, match.index)}</div>
                        )}
                    </div>
                );
            }

            newSections.push(
                <RenderCodeBlock language={language} code={code} key={newSections.length} />
            );

            remainingText = remainingText.slice(match.index + fullMatch.length);
        }

        if (remainingText) {
            newSections.push(
                <div key={newSections.length}>
                    {!isSender ? (
                    <ReactMarkdown components={components} className="markdown-content" children={remainingText} />
                    ) : (
                        <div className={'preserve-whitespace'}>{remainingText}</div>
                    )}

                </div>
            );
        }

        return newSections;
    }, [message]);

    return (
        <div ref={bubbleRef} className={bubbleContainerClass}>
            <div className={`chat-bubble ${bubbleClass}`}>
                {sections}
            </div>
        </div>
    );
}

export default memo(ChatBubble);
