import React, {useRef, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../services/authServices";

const LoginWidget = ({setIsVisible, setShowSignup}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { login, isLoggedIn } = useAuth();
    const navigate = useNavigate(); // Initialize the navigate function
    const emailInputRef = useRef(null);

    const handleLogin = async () => {
        try {
            await login(username, password);
            // Redirect to the protected route after successful login
            console.log('Login successful, nav to chat');
            navigate('/chat');
        } catch (error) {
            console.error('Login failed:', error);
            setErrorMessage('Invalid username or password')
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleLogin();
        }
    };

    const handleRedirectToCreate = async () => {
        setIsVisible(false);
        setShowSignup(true);
    };

    return (
        <div className={'signup-container'}>
            <div className={'signup'}>
                <div className={'signup-inner'}>
                    <button className={'close-button'} onClick={() => setIsVisible(false)}>X</button>

                    <div>
                        <div className={'signup-welcome'}>Sign In</div>
                        <div className={'signup-field'}>
                            {errorMessage.length > 0 && <div className="error-message">{errorMessage}</div>}
                            <label htmlFor="email" onClick={() => emailInputRef.current.focus()}>Email</label>
                            <input
                                ref={emailInputRef}
                                type="email"
                                placeholder="Username"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                className="input-field"
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className={'signup-field'}>
                            <label htmlFor="password" onClick={() => document.getElementById('password').focus()}>Password</label>
                            <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className="input-field"
                            onKeyDown={handleKeyDown}
                        />
                        </div>
                        <button onClick={handleLogin} className="login-button">
                            Sign In
                        </button>
                        <div className={'signup-or'}>
                            <div className={'signup-signin'}>
                                Don't have an account? <Link className={'blue-link'} to="/" onClick={handleRedirectToCreate}>Create Account</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginWidget;
