import React, {useState, useContext, useRef, useEffect, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import '../styles/ChatList.css';
import '../styles/App.css';
import {AppContext} from '../context/appContext';
import {AuthContext} from '../context/authContext';
import {useNavigate} from 'react-router-dom';
import {FaCheck, FaEdit, FaTimes, FaTrash} from 'react-icons/fa';

const ChatItem = React.memo(function ChatItem({
                                                  conv,
                                                  isSelected,
                                                  onConversationClick,
                                                  onDeleteConversation,
                                                  onTitleUpdate,
                                                  editingChatId,
                                                  setEditingChatId,
                                              }) {
    const [newTitle, setNewTitle] = useState(conv.isUserTitle ? conv.userTitle : conv.title);
    const titleInputRef = useRef(null);

    useEffect(() => {
        if (editingChatId === conv._id && titleInputRef.current) {
            titleInputRef.current.focus();
            const len = titleInputRef.current.value.length;
            titleInputRef.current.setSelectionRange(len, len);
        }
    }, [editingChatId, conv._id]);

    const handleTitleUpdate = useCallback(() => {
        onTitleUpdate(newTitle, conv._id);
        setEditingChatId(null);
    }, [newTitle, conv._id, onTitleUpdate, setEditingChatId]);

    const startEditing = useCallback(() => {
        setEditingChatId(conv._id);
        setNewTitle(conv.isUserTitle ? conv.userTitle : conv.title);
    }, [conv._id, conv.isUserTitle, conv.userTitle, conv.title, setEditingChatId]);

    const stopEditing = useCallback(() => {
        setEditingChatId(null);
    }, [setEditingChatId]);

    return (
        <li
            className={`${isSelected ? 'selected-chat' : 'chat-item'}`}
            onClick={(e) => {
                if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'BUTTON') {
                    if (editingChatId !== conv._id) {
                        onConversationClick(conv._id);
                    } else {
                        stopEditing();
                    }
                }
            }}
        >
            {editingChatId === conv._id ? (
                <>
                    <input
                        className="chat-title title-input"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleTitleUpdate();
                            }
                        }}
                        ref={titleInputRef}
                    />
                    <button className="chat-control" onClick={(e) => {
                        e.stopPropagation();
                        handleTitleUpdate();
                    }}><FaCheck/></button>
                    <button className="chat-control" onClick={(e) => {
                        e.stopPropagation();
                        stopEditing();
                    }}><FaTimes/></button>
                </>
            ) : (
                <>
                    <div className="chat-title">{conv.isUserTitle ? conv.userTitle : conv.title}</div>
                    <div className={`${isSelected ? 'chat-controls-selected' : 'chat-controls'}`}>
                        <div className="gradient-chat-control chat-control"></div>
                        <div className="chat-control-holder">
                            <button
                                className="chat-edit-title chat-control"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    startEditing();
                                }}
                            >
                                <FaEdit/>
                            </button>
                            <button
                                className="chat-delete chat-control"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteConversation(conv._id);
                                }}
                            >
                                <FaTrash/>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </li>
    );
}, (prevProps, nextProps) => {
    // Custom comparison function for React.memo
    return (
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.editingChatId === nextProps.editingChatId &&
        prevProps.conv._id === nextProps.conv._id &&
        prevProps.conv.isUserTitle === nextProps.conv.isUserTitle &&
        prevProps.conv.userTitle === nextProps.conv.userTitle &&
        prevProps.conv.title === nextProps.conv.title
    );
});

const ChatList = React.memo(function ChatList({
                                                  onConversationClick,
                                                  onNewChatClicked,
                                                  onDeleteConversation,
                                                  onTitleUpdate
                                              }) {
    const {chatList, selectedChat} = useContext(AppContext);
    const {isLoggedIn} = useContext(AuthContext);
    const navigate = useNavigate();

    const [editingChatId, setEditingChatId] = useState(null);

    const chatIds = useMemo(() => Object.keys(chatList).filter(id => id !== '-1' && id !== undefined), [chatList]);

    const groupedByDate = useMemo(() => {
        return chatIds.reduce((acc, id) => {
            const conv = chatList[id];
            let dateObj = new Date(conv.date);
            if (isNaN(dateObj.getTime())) {
                dateObj = new Date();
            }
            // Use ISO date string for grouping to preserve year information
            const dateStr = dateObj.toISOString().split('T')[0];
            if (!acc[dateStr]) {
                acc[dateStr] = [];
            }
            acc[dateStr].push(id);
            return acc;
        }, {});
    }, [chatIds, chatList]);

    const sortedDates = useMemo(() => {
        return Object.keys(groupedByDate).sort((a, b) => new Date(b) - new Date(a));
    }, [groupedByDate]);

    const formatDate = useCallback((dateStr) => {
        const date = new Date(dateStr);
        const now = new Date();
        const isThisYear = date.getFullYear() === now.getFullYear();
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: isThisYear ? undefined : 'numeric'
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }, []);

    return (
        <div className="chat-list">
            {isLoggedIn && (
                <ul className="chat-list-inner">
                    {sortedDates.map(date => (
                        <React.Fragment key={date}>
                            <div className="date-list">
                                <li className="date-label">{formatDate(date)}</li>
                                {groupedByDate[date].map((id) => (
                                    <ChatItem
                                        key={id}
                                        conv={chatList[id]}
                                        isSelected={id === selectedChat}
                                        onConversationClick={onConversationClick}
                                        onDeleteConversation={onDeleteConversation}
                                        onTitleUpdate={onTitleUpdate}
                                        editingChatId={editingChatId}
                                        setEditingChatId={setEditingChatId}
                                    />
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                    <div style={{height: '30px'}}></div>
                </ul>
            )}
            <div className="gradient"></div>
            {isLoggedIn && <button className="add-chat-button" onClick={onNewChatClicked}>New Chat</button>}
            {!isLoggedIn && (
                <div className="history-sign-in body-message">
                    Sign in to view your previous conversations.
                    <button className="login-button" onClick={() => navigate('/', {state: {signIn: true}})}>Sign
                        In</button>
                    <br/>
                    <div className="body-message">Don't have an account?</div>
                    <button className="get-started-button"
                            onClick={() => navigate('/', {state: {createAccount: true}})}>Create Account
                    </button>
                </div>
            )}
        </div>
    );
});

ChatList.propTypes = {
    onConversationClick: PropTypes.func.isRequired,
    onNewChatClicked: PropTypes.func.isRequired,
    onDeleteConversation: PropTypes.func.isRequired,
    onTitleUpdate: PropTypes.func.isRequired
};

export default ChatList;