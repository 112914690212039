import '../styles/App.css';

import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import { AuthProvider } from '../context/authContext'; // Adjust the import according to your file structure

import { IntlProvider } from 'react-intl';
import loadLocaleData from '../utils/loadLocaleData';
import { useAuth } from '../services/authServices';
import { AppProvider } from "../context/appContext";

import Chat from './chat.js';

import Landing from './landing.js';
import Settings from './settings.js';
import Admin from "./admin";
import debounce from 'lodash.debounce';
import LoadingOverlay from "../components/loadingOverlay";


function App() {
    const [locale, setLocale] = useState('en');
    const [messages, setMessages] = useState({});
    const [isLoading, setIsLoading] = useState(true); // <-- Add this line

    useEffect(() => {
        const fetchLocaleData = async () => {
            const defaultLang = navigator.language.split(/[-_]/)[0];
            console.log(`Detected locale: ${defaultLang}`); // Debugging line

            setLocale(defaultLang);

            try {
                const importedMessages = await loadLocaleData(defaultLang);
                setMessages(importedMessages.default || importedMessages);
            } catch (error) {
                console.error(`Failed to load locale data: ${error}`);
            }

            setIsLoading(false); // <-- Set loading to false even if it fails
        };

        fetchLocaleData().then(r => {});
    }, []);

    useEffect(() => {
        let initialScrollPosition;

        const handleFocusIn = () => {
            initialScrollPosition = window.scrollY;
        };

        const debouncedHandleFocusOut = debounce(() => {
            window.scrollTo(0, initialScrollPosition);
        }, 300); // 300 milliseconds delay

        const handleFocusOut = () => {
            debouncedHandleFocusOut();
        };

        window.addEventListener('focusin', handleFocusIn);
        window.addEventListener('focusout', handleFocusOut);

        return () => {
            window.removeEventListener('focusin', handleFocusIn);
            window.removeEventListener('focusout', handleFocusOut);
        };
    }, []);


    if (isLoading) {  // <-- Don't render anything if loading
        return null;
    }

    const AppRoutes = () => {
        const { setIsLoggedIn, isLoggedIn, checkSession} = useAuth();
        useEffect(() => {

            const runCheckSession = async () => {
                const sessionActive = await checkSession();
            };
            runCheckSession();
        }, [setIsLoggedIn, checkSession]);

        return (
            <IntlProvider locale={locale} messages={messages}>
                <Router>
                    <div className={"app-page"}>
                        <Routes>
                            <Route path="/" element={<Landing />} />
                            <Route path="/chat" element={<Chat />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/admin" element={isLoggedIn ? <Admin /> : <Navigate to="/" />} />
                        </Routes>

                    </div>
                </Router>
            </IntlProvider>

        );
    };


    return (
        <AppProvider>
        <AuthProvider>
            <AppRoutes />
        </AuthProvider>
        </AppProvider>
    );


}

export default App;
