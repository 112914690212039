import axios from 'axios';
import { AuthContext } from "../context/authContext";
import { AppContext } from "../context/appContext";

import {useContext} from "react";
import { SERVER_URL } from '../constants/constants'; // Import constants
axios.defaults.withCredentials = true;

export const useChatService = () => {
    const { chatList, setChatList, updateChatList, setChatListFromArray, selectedChat, setSelectedChat, selectedModel, setSelectedModel} = useContext(AppContext)

    const newChat = async () => {
        console.log("Adding new chat...");

        try {
            const response = await axios.post(`${SERVER_URL}/newchat`, { model : selectedModel.name });
            if (response.status === 201) {
                console.log(response.data.message);
            }

            // Update the chat list

            console.log(JSON.stringify(response.data.conversation))
            updateChatList(response.data.conversation._id, response.data.conversation);
            setSelectedChat(response.data.conversation._id);

        } catch (error) {
            console.error('Error during newchat:', error);
            // Handle other potential errors (e.g., network issues)
            throw error;
        }
    };

    const getChat = async (chatId) => {
        console.log("Retrieving chat...");

        try {
            const response = await axios.post(`${SERVER_URL}/getchat`, { id : chatId });
            if (response.status === 201) {
                console.log(response.data.message);
            }
            updateChatList(response.data._id, response.data);
            setSelectedChat(response.data._id);
            console.log("Selected chat", selectedChat);
        } catch (error) {
            // Usually indicates that the user is not logged in
            console.log('Error during getchat:');
        }
    };

    const deleteChat = async (chatId) => {
        console.log("Delete chat...");

        try {
            const response = await axios.post(`${SERVER_URL}/deletechat`, { id : chatId });
            if (response.status === 200) {
                setSelectedChat(-1);
                console.log("Deleted chat", chatId);
            }
        } catch (error) {
            // Usually indicates that the user is not logged in
            console.log('Error during deletechat:');
        }
    };

    const updateChatTitle = async (chatId, newTitle) => {
        console.log("Update chat title...", newTitle);

        try {
            const response = await axios.post(`${SERVER_URL}/updatechattitle`, { id : chatId , title: newTitle});
            if (response.status === 200) {
                console.log("Updated chat title", chatId, newTitle);
            }
        } catch (error) {
            // Usually indicates that the user is not logged in
            console.log('Error during update title:');
        }
    };

    const getChatList = async () => {
        try {
            // User axios to get the chat list
            const response = await axios.get(`${SERVER_URL}/chatlist`, { withCredentials: true });

            if (response.status === 200) {
                console.log("Chat list retrieved successfully");
                // Log the response data
                // Further client-side logic here, like redirecting to login page
                setChatListFromArray(response.data);
             //   setChatList(response.data);
            }
        } catch (error) {
            // Usually indicates that the user is not logged in
            console.log("An error occurred during get chat list:");
        }
    };

    return {
        newChat,
        getChat,
        getChatList,
        deleteChat,
        updateChatTitle
    };
};
