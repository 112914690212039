import React, {useState} from 'react';
import { useAuth } from '../services/authServices';
import NavBar from "../components/navBar";

function Admin() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [loginUsername, setLoginUsername] = useState('');
    const [loginPassword, setLoginPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageLogin, setErrorMessageLogin] = useState('');

    const { login, register } = useAuth();

    const handleLogin = async () => {
        try {
            await login(loginUsername, loginPassword);
            setErrorMessageLogin('Success')
        } catch (error) {
            console.error('Login failed:', error);
            setErrorMessageLogin('Invalid credentials')
        }
    };

    const handleRegister = async () => {
        try {
            await register(username, password);
            setErrorMessage('Success')
        } catch (error) {
            if (error.response && error.response.status === 409) {
                // Conflict, user already exists
                setErrorMessage('Username already exists')
                console.log('Error:', error.response.data); // error.response.data should contain your JSON payload
            } else if (error.response && error.response.status === 400) {
                // Bad Request, likely "password too short"
                setErrorMessage('Password too short')
                console.log('Error:', error.response.data);
            } else {
                // Some other issue
                setErrorMessage('An unknown error occurred')
                console.log('An unknown error occurred:', error);
            }
        }
    };

    return (
        <div className={'page'}>
            <NavBar />
            <h1>Admin Page</h1>

            <div className={"login-container"}>
                <div className={"login-controls"}>
                    <h2>Create account</h2>
                    <div className={"message-container"}>{errorMessage}</div>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        className="input-field"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        className="input-field"
                    />
                    <button onClick={handleRegister} className="get-started-button">
                        Register User
                    </button>
                </div>

                <div className={"login-controls"}>
                    <h2>Log In</h2>
                    <div className={"message-container"}>{errorMessageLogin}</div>
                    <input
                        type="text"
                        placeholder="Username"
                        value={loginUsername}
                        onChange={e => setLoginUsername(e.target.value)}
                        className="input-field"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={loginPassword}
                        onChange={e => setLoginPassword(e.target.value)}
                        className="input-field"
                    />
                    <button onClick={handleLogin} className="get-started-button">
                        Test Log In
                    </button>
                </div>
            </div>
            {/* Your page content here */}
        </div>
    );
}

export default Admin;