import React from 'react';

const LoadingOverlayBar = ({message = "Offloading context window into model weights...", progress = 0}) => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                maxWidth: '300px',
                width: '100%'
            }}>
                <div style={{
                    width: '100%',
                    height: '8px',
                    backgroundColor: '#e5e7eb',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    marginBottom: '16px'
                }}>
                    <div style={{
                        width: `${progress}%`,
                        height: '100%',
                        backgroundColor: '#3b82f6',
                        transition: 'width 0.01s ease-in-out'
                    }}/>
                </div>
                <p style={{
                    color: '#374151',
                    fontSize: '18px',
                    fontWeight: '600',
                    marginBottom: '8px',
                    textAlign: 'center'
                }}>{message}</p>
                <p style={{
                    color: '#6b7280',
                    fontSize: '14px'
                }}>{progress.toFixed(0)}%</p>
            </div>
        </div>
    );
};

export default LoadingOverlayBar;