import React, {createContext, useContext, useEffect, useState} from 'react';

// Create a context object
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
    const [selectedChat, setSelectedChat] = useState(-1);
    const [selectedModel, setSelectedModel] = useState(null); // State for selected model
    const [userName, setUsername] = useState('');
    const [hasScrolled, setHasScrolled] = useState(false);
    const [userScroll, setUserScroll] = useState(false);

    const [chatList, setChatList] = useState({
        '-1': {
            id: -1,
            title: 'New Chat',
            date: '2023-10-20',
            messages: []
        }
    });


    const [visibleChatList, setVisibleChatList] = useState({
        '-1': {
            id: -1,
            title: 'New Chat',
            date: '2023-10-20',
            messages: []
        }
    });

    useEffect(() => {
        console.log(`selectedChat changed: ${selectedChat}`);
    }, [selectedChat]);

    // Function to add or update a chat in chatList
    const updateChatList = (chatId, chatData) => {
        console.log("Updating chat list", chatId, JSON.stringify(chatData))
        setChatList(prevChatList => ({
            ...prevChatList,
            [chatId]: chatData
        }));
        console.log("Updating chat list", chatId)

    };

    // const addEmptyChat = (chatId, firstMessage) => {
    //     const emptyConversation = {
    //             id: chatId,
    //             title: 'New Chat',
    //             date: Date.now(),
    //             messages: [{messages: firstMessage, isSender: true}]
    //         };
    //     const newChatList = {};
    //     // Copy the old chatList
    //     Object.keys(chatList).forEach(key => {
    //         newChatList[key] = chatList[key];
    //     });
    //     newChatList[chatId] = emptyConversation;
    //     setChatList(newChatList);
    // }

    // Function to set the chatList from an array
    const setChatListFromArray = (chatArray) => {
        const emptyConversation = {
                id: -1,
                title: 'New Chat',
                date: Date.now(),
                messages: []
            };

        const newChatList = {};
        chatArray.forEach(chat => {
            newChatList[chat._id] = chat;
        });

        newChatList[-1] = emptyConversation;
        setChatList(newChatList);
    };

    const updateMessageData = (chatId, newMessages) => {
        if (chatList[chatId]) { // Make sure the chatId exists

            setChatList(prevChatList => ({
                ...prevChatList,
                [chatId]: {
                    ...prevChatList[chatId],
                    messages: [...prevChatList[chatId].messages, ...newMessages]
                }
            }));
        } else {
            // If chatId does not exist, create a new chat with the chatId
            const emptyConversation = {
                id: chatId,
                title: 'New Chat',
                date: Date.now(),
                messages: []
            };

            setChatList(prevChatList => ({
                ...prevChatList,
                [chatId]: {
                    ...prevChatList[chatId],
                    messages: [emptyConversation]
                }
            }));

            setChatList(prevChatList => ({
                ...prevChatList,
                [chatId]: {
                    ...prevChatList[chatId],
                    messages: [...prevChatList[chatId].messages, ...newMessages]
                }
            }));

            // chatList[chatId] = emptyConversation;
            // setChatList(chatList)
            //
            // updateMessageData(chatId, newMessages)

            // Output all the chat ids to the console
            console.log("Chat id does not exist", chatId)
        }
    };

    const clearConversation = (chatId) => {
        // const currentChat = chatList[chatId]
        // // If chatId does not exist, create a new chat with the chatId
        // const emptyConversation = {
        //     id: chatId,
        //     title: "",
        //     date: Date.now(),
        //     messages: []
        // };
        //
        // chatList[chatId] = emptyConversation;
        // setChatList(chatList)
        //
        // if (chatList[chatId]) { // Make sure the chatId exists
        //     setChatList(prevChatList => ({
        //         ...prevChatList,
        //         [chatId]: {
        //             ...prevChatList[chatId],
        //         }
        //     }));
        // }

        setChatList(prevChatList => ({
            ...prevChatList,
            [chatId]: {
                ...prevChatList[chatId],
                messages: []
            }
        }));

    };

    const applyChatList = () => {
        setVisibleChatList(chatList)
    }

    const updateChatTitle = (chatId, newTitle) => {
        if (chatList[chatId]) { // Make sure the chatId exists
            setChatList(prevChatList => ({
                ...prevChatList,
                [chatId]: {
                    ...prevChatList[chatId],
                    title: newTitle
                }
            }));
        }
    }

    const updateLatestMessage = (chatId, latestMessage) => {
        if (chatList[chatId]) { // Make sure the chatId exists
            setChatList(prevChatList => ({
                ...prevChatList,
                [chatId]: {
                    ...prevChatList[chatId],
                    messages: [
                        ...prevChatList[chatId].messages.slice(0, -1), // All except the last message
                        latestMessage // The new latest message
                    ]
                }
            }));
        }
    };

    return (
        <AppContext.Provider value={{
            userName,
            setUsername,
            chatList,
            setChatList,
            updateChatList,
            setChatListFromArray,
            updateMessageData,
            clearConversation,
            updateLatestMessage,
            selectedChat,
            updateChatTitle,
            setSelectedChat,
            selectedModel,
            setSelectedModel,
            hasScrolled,
            setHasScrolled,
            userScroll,
            setUserScroll,
            applyChatList,
            visibleChatList}}>
            {children}
        </AppContext.Provider>
    );
};


// Create a hook to use the context
export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp must be used within an AppProvider');
    }
    return context;
};
