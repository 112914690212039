const isSSL = JSON.parse(process.env.REACT_APP_HX_SSL);

export const SERVER_PORT = process.env.REACT_APP_HX_BACKEND_PORT;  // Define server port as a constant
export const SERVER_DOMAIN = process.env.REACT_APP_HX_DOMAIN;  // Define server domain as a constant
export const WS_URL = isSSL ? `wss://${SERVER_DOMAIN}:${SERVER_PORT}` : `ws://${SERVER_DOMAIN}:${SERVER_PORT}`;

export const SERVER_URL = isSSL ? `https://${SERVER_DOMAIN}:${SERVER_PORT}` : `http://${SERVER_DOMAIN}:${SERVER_PORT}`;  // Define server URL as a constant
export const SERVER_URL_TLD = isSSL ? `https://${SERVER_DOMAIN}` : `http://${SERVER_DOMAIN}`;  // Define server URL as a constant

// export const SYSTEM_PROMPT = "" +
//     "Continue the chat dialogue below. " +
//     "Write a single reply for the character. " +
//     "You are an AI model trained to assist with the processing of insurance claims. " +
//     "You have access to all relevant coverage codes. \n\n";


export const SYSTEM_PROMPT = 'Continue the chat dialogue below. Write a single reply for the character. "".\n\n'