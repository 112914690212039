import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AccountDropdown from "./accountDropdown";
import {useAuth} from "../services/authServices";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo} from '../assets/logo.svg';

const NavBar = () => {
    const { formatMessage: f } = useIntl();  // Destructure and rename
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/', { state: { createAccount: true } });
    };

    return (
        <nav>
            <div className={"nav-container"}>

                <div className={"nav-brandname"}>
                    <Logo className={"logo"} style={{width:'30px', height:'30px'}}/>
                    <a href={'/'}><b>{f({ id: 'hexis_brand' })}</b></a>
                </div>

            <ul className={"nav-list"}>
                <li><Link to="/chat">{f({ id: 'chat_nav' })}</Link></li>
                {isLoggedIn && <AccountDropdown/>}

                {!isLoggedIn && <li><div onClick={handleClick} className={'no-account-message'}>Create Account</div></li>}
            </ul>
            </div>
        </nav>
    );
};

export default NavBar;
