import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from '../services/authServices';

import LightingEffects from "../components/lightingEffects";
import NavBarLanding from "../components/navBarLanding";
import hexisLoop from '../assets/hexis-loop.webp';
import '../styles/landing.css';

import {useLocation, useNavigate} from 'react-router-dom';
import SignUpWizard from "../components/signUpWizard";
import LoginWidget from "../components/loginWidget";
// Adjust the import according to your file structure

function Landing() {
    const { formatMessage: f } = useIntl();  // Destructure and rename
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Initialize the navigate function
    const [errorMessage, setErrorMessage] = useState('');
    const [showSignup, setShowSignup] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const { state } = useLocation();
    const { login, isLoggedIn } = useAuth();

    const lightColors = [
        0.396754, 0.514917, 0.973445,
        0.693871, 0.838798, 0.973445,
        1.000000, 1,1,
        0.5, 0.8, 0.3,
        1.0, 0.8, 0.3,
    ];

    const start = -200;
    const end = 200;
    const step = (end - start) / 2;
    const lights = [];
    // Loop over and create the lights array
    for (let i = 0; i < 3; i++) {
        const position = [start + step * i, 0];
        const color = lightColors.slice(i * 3, i * 3 + 3);
        lights.push({ position, color });
    }


    const handleLogin = async () => {
        try {
            await login(username, password);
            // Redirect to the protected route after successful login
            console.log('Login successful, nav to chat');
            navigate('/chat');
        } catch (error) {
            console.error('Login failed:', error);
            setErrorMessage('Invalid username or password')
        }
    };

    useEffect(() => {
        if (state && state.createAccount) {
            setShowLogin(false)
            setShowSignup(true);
        }
    }, [state]);

    const handleLoginClick = () => {
        setShowLogin(true)
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleLogin();
        }
    };

    const handleRegister = async () => {
        // try {
        //     const newUserData = await register(username, password);
        // } catch (error) {
        //     console.error('Registration failed:', error);
        // }

        setShowSignup(true);
    };

    return (
        <div>
            {showSignup &&
                <SignUpWizard setIsVisible={setShowSignup} setShowLogin={setShowLogin}/>
            }

            {showLogin && <LoginWidget setIsVisible={setShowLogin} setShowSignup={setShowSignup}/>}
        <div className="page">
            <div className={'fixed-fix'}></div>
            <NavBarLanding loginClicked={handleLoginClick} handleRegister={handleRegister}/>
            <div className="container">
                <div className="content">

                    <div className="message-container">
                        <div className={'messaging_1_container'}>
                        <div className={'hexis_message'}>{f({ id: 'hexis_message' })}</div>
                        <div className={'hexis_message_sub_heading'}>{f({ id: 'hexis_message_1' })}</div>
                        <div className={'hexis_message_body'}>{f({ id: 'hexis_message_2' })}</div>
                        </div>

                        {/* Your page content here */}
                    </div>
                </div>


            </div>

            <LightingEffects lights={lights}/>
        </div>

            <div className={"alt-container"}>
                <div className="page">
                    <div className="container">
                        <div className="content">

                            <div className="message-container">
                            <div className={'messaging_1_container'}>
                                <div className={'hexis_message_alt'}>Train</div>
                                <div className={'hexis_message_sub_heading_alt'}>{f({ id: 'hexis_message_5' })}</div>
                            </div>

                            {/* Your page content here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page">
                <div className="container">

                    <div className="content">

                        <div className="message-container">
                            <div className={'messaging_1_container'}>
                                <div className={'hexis_message'}>Test</div>
                                <div className={'hexis_message_sub_heading'}>Benchmark Performance</div>
                                <div className={'hexis_message_body'}>Evaluating model coherence - ensure your model meets your needs</div>
                            </div>

                            {/* Your page content here */}
                        </div>
                    </div>
                </div>
            </div>

            <div className={"alt-container"}>
                <div className="page">
                    <div className="container">
                        <div className="content">

                            <div className="message-container">
                                <div className={'messaging_1_container'}>
                                    <div className={'hexis_message_alt'}>Deploy</div>
                                    <div className={'hexis_message_sub_heading_alt'}>Your model in your control.</div>
                                    <div className={'hexis_message_body_alt'}>{f({ id: 'hexis_message_2' })}</div>


                                </div>

                                {/* Your page content here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"user-case-container"}>
                <div className="page">
                    <div className="container">
                        <div className="content">

                            <div className="message-container">
                                <div className={'messaging_1_container'}>
                                    <div className={'hexis_message_alt'}>Use Cases</div>

                                    <div className={'user-case-box'}>
                                        <div className={'user_case_sub_heading_alt'}>Patient Onboarding</div>
                                        <div className={'use_case_message_body_alt'}>Small clinics are burdened with patient intake</div>
                                    </div>

                                    <div className={'user-case-box'}>
                                        <div className={'user_case_sub_heading_alt'}>Coding Assistant</div>
                                        <div className={'use_case_message_body_alt'}>Modern coding assistants are train on large datasets of public code, but they don't have knowledge of your specific codebase.</div>
                                    </div>

                                    <div className={'user-case-box'}>
                                        <div className={'user_case_sub_heading_alt'}>Marketing Assistant</div>
                                        <div className={'use_case_message_body_alt'}>Description here</div>
                                    </div>

                                </div>

                                {/* Your page content here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page">
                <div className="container">

                    <div className="content">

                        <div className="message-container">
                            <div className={'messaging_1_container'}>
                                <div className={'hexis_message'}>Contact us to learn more</div>
                                <div className={'hexis_message_sub_heading'}>sales@attentio.ai</div>
                            </div>

                            {/* Your page content here */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Landing;
