import React, {useState, useEffect, useRef, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../services/authServices';
import {AppContext, useApp} from "../context/appContext";
import '../styles/ModelDropdown.css';

const ModelDropDown = () => {
    const { userName } = useApp();
    const [isOpen, setIsOpen] = useState(false);
    const [models, setModels] = useState([]);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { selectedModel, setSelectedModel } = useContext(AppContext)


    useEffect(() => {
        const hardcodedModels = [
            {
                display: "Mistral 7B",
                name: "melty-tensor",
                model_description: "A performant base model compatible with Melty Tensor training."
            },

            { display: "TextGenWebUI", name: "local", model_description: "Text-gen-webui model backend" },
            { display: "GPT4", name: "gpt4-turbo", model_description: "Versatile AI model with advanced natural language understanding, suitable for a broad range of applications." }
        ];
        setModels(hardcodedModels);
        setSelectedModel(hardcodedModels[0]);

        // API call will be here in the future

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleModelSelect = (model) => {
        setSelectedModel(model);
        setIsOpen(false);
        console.log("Model selected:", model.name);
        // Additional logic for model selection can be added here
    };

    return (
        <div className="model-selector">
            <div className="dropdown" ref={dropdownRef}>
                <button id="model-dropdown" className={'dropdown-title'} onClick={toggleDropdown}>
                    {selectedModel ? selectedModel.display : "Select a model"} {isOpen ? '▼' : '▼'}
                </button>

                {isOpen && (
                    <ul className="dropdown-menu">
                        {models.map(model => (
                            <li key={model.name} onClick={() => handleModelSelect(model)}>
                                <b>{model.display}</b> - <small>{model.model_description}</small>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ModelDropDown;
