export default async function loadLocaleData(locale) {
    let messages;
    try {
        messages = await import(`../locales/${locale}.json`);
    } catch (error) {
        // Fallback to English if the locale file is not found
        messages = await import('../locales/en.json');
    }
    return messages.default || messages;
}
