import React from 'react';
import NavBar from "../components/navBar";
import axios from "axios";
import {SERVER_URL} from "../constants/constants";

function Settings() {

    async function testBackendCrash() {
        await axios.post(`${SERVER_URL}/crash`, {
            data: "Hello my name is Lukas",
        });
    }

    return (
        <div>
            <NavBar />
            <h1>Settings Page</h1>
            <button onClick={testBackendCrash}>Test Backend Crash</button>
            {/* Your page content here */}
        </div>
    );
}

export default Settings;